import {useSnapshot} from "valtio";
import {uiState} from "./stores/model";
import FadeDiv from "./components/UI/FadeDiv";
import {googleSheet} from "./stores/data";
import {useEffect, useRef, useState} from "react";

const getJson = (path) => {
    return window.axios.get(path)
        .then(response => {
            const json = response.data
            // console.log(json)
            return json
        })
        .catch(error => {
            console.error('Error:', error)
        })
}

function getFormattedDate() {
    const now = new Date();

    let year = now.getFullYear().toString().slice(-2);
    let month = (now.getMonth() + 1).toString().padStart(2, '0');
    let day = now.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
}

const App = () => {
    const [name, setName] = useState(localStorage.getItem("initialName") || "mawer")
    const prevNameRef = useRef(name)
    const [inputRecord, setInputRecord] = useState(Array.from({length: 9})
        .reduce((acc, _, i) => {
            const key = `BR${i + 1}`;
            acc[key] = `${name}/`;
            return acc;
        }, {}))

    useEffect(() => {
        const prevName = prevNameRef.current;
        const updatedInputRecord = Object.keys(inputRecord).reduce((acc, key) => {
            const value = inputRecord[key];
            const regex = new RegExp(`^${prevName}/`);
            if (regex.test(value)) {
                acc[key] = `${name}/`;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});

        setInputRecord(updatedInputRecord);
        prevNameRef.current = name;
        localStorage.setItem("initialName", name)
    }, [name]);

    const [toolboxData, setToolboxData] = useState({})
    const getToolboxData = async () => {
        if (googleSheet) {
            setToolboxData(googleSheet)
        }
    }
    useEffect(() => {
        getToolboxData()
    }, [])

    const createSequenceArray = (prefix, start, end) =>
        Array.from({length: end - start + 1}, (_, index) => `${prefix}${index + start}`);
    const brGroups = createSequenceArray("BR", 1, 9);

    const uniqueGroups = toolboxData.copy ? [
        ...brGroups, "Setting"
        // 'All',
        // ...new Set(toolboxData.tag.map(item => item.group)),
        // ...new Set(toolboxData.copy.map(item => item.group))
    ] : []
    const [currentGroup, setCurrentGroup] = useState("BR1")

    const appendTodayText = () => {
        setInputRecord(p => ({...p, [currentGroup]: `${p[currentGroup]}${getFormattedDate()}`}))
    }

    useEffect(() => {
        const toggleIsActive = (event) => {
            if (event.altKey && event.key >= '1' && event.key <= '9') {
                setCurrentGroup(`BR${event.key}`);
            } else if (event.altKey && event.key === '0') {
                setCurrentGroup(`Setting`);
            } else if (event.metaKey && event.shiftKey && event.key === '8') {
                appendTodayText()
            }
        };

        window.addEventListener('keydown', toggleIsActive);
        return () => {
            window.removeEventListener('keydown', toggleIsActive);
        };
    }, []);

    function setToPreviousGroup() {
        setCurrentGroup((current) => {
            const index = uniqueGroups.indexOf(current);
            const prevIndex = index - 1 < 0 ? uniqueGroups.length - 1 : index - 1;
            return uniqueGroups[prevIndex];
        });
    }

    function setToNextGroup() {
        setCurrentGroup((current) => {
            const index = uniqueGroups.indexOf(current);
            const nextIndex = index + 1 >= uniqueGroups.length ? 0 : index + 1;
            return uniqueGroups[nextIndex];
        });
    }

    window.gitHelper = {
        visible: true,
        'ArrowLeft': setToPreviousGroup,
        'ArrowRight': setToNextGroup,
    }

    return <div className="fixed inset-0 flex flex-col items-center p-4 sm:p-10 gap-4">
        <div className="relative text-2xl font-bold text-cyan-700 select-none">Git Helper</div>
        <div className="flex gap-4 w-full max-w-2xl border-b overflow-x-auto scrollbar-hide">
            {uniqueGroups && uniqueGroups.map((el, i) => {
                const isActive = currentGroup === el;
                const clickHandler = () => setCurrentGroup(el);
                return (
                    <div
                        className={`${isActive ? 'text-white bg-cyan-700' : 'text-cyan-700 hover:text-cyan-500 cursor-pointer'} transition-colors px-2 py-1 rounded-t-lg select-none`}
                        key={i}
                        onClick={clickHandler}>
                        {el}
                    </div>
                )
            })}
        </div>


        <div className="relative flex-1 overflow-scroll flex flex-col items-center gap-4 w-full max-w-2xl">
            {brGroups.includes(currentGroup) &&
                <div className="rounded-lg border p-2 flex gap-2 w-full items-center">
                    <Text className="border-r pr-2">設置分支名稱</Text>
                    <input
                        className="outline-none w-full border-b rounded-none"
                        value={inputRecord[currentGroup]}
                        onChange={(e) => setInputRecord(p => ({...p, [currentGroup]: e.target.value}))}
                    />
                    <div className="border-l pl-2 whitespace-pre-wrap">
                        <div
                            className="text-cyan-700 hover:text-cyan-500 active:text-cyan-700 transition-colors whitespace-nowrap cursor-pointer w-14 text-center select-none"
                            onClick={appendTodayText}>{"Today"}</div>
                    </div>
                </div>}

            {brGroups.includes(currentGroup) && toolboxData.copy
                && toolboxData.copy.filter(el => el.group === "Git").map((el, i) => <CopyItem key={i}
                                                                                              el={el}
                                                                                              copyText={"git " + el.content.replaceAll("<BR>", inputRecord[currentGroup])}
                />)}
            {brGroups.includes(currentGroup) && toolboxData.copy
                && toolboxData.copy.filter(el => el.group === "PureCopy").map((el, i) => <CopyItem key={i}
                                                                                                   el={el}
                                                                                                   copyText={el.content}
                />)}
            {currentGroup === "Setting" &&
                <>
                    <div className="rounded-lg border p-2 flex gap-2 w-full items-center flex gap-2">
                        <Text className="border-r pr-2">設置主要名稱</Text>
                        <input
                            className="outline-none w-full border-b rounded-none"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                </>
            }


            {/* {toolboxData.tag && toolboxData.tag.filter(el=>currentGroup==='All'?true:currentGroup===el.group).map((el,i)=><TagItem key={i} el={el}/>)} */}

        </div>

    </div>
}

const CopyItem = ({el, copyText}) => {
    const {title, content, detail} = el
    const [isCopied, setIsCopied] = useState(false)
    const copyToClipboard = () => {
        navigator.clipboard.writeText(copyText)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1000)
    }
    return <div className="rounded-lg border p-2 flex gap-2 w-full items-center justify-between">
        <div className="flex gap-2">
            <Text>{title}</Text>
            <Text className="border-l pl-2">{detail}</Text>
        </div>
        <div className="border-l pl-2 whitespace-pre-wrap">
            <div
                className="text-cyan-700 hover:text-cyan-500 active:text-cyan-700 transition-colors whitespace-nowrap cursor-pointer w-14 text-center select-none"
                onClick={copyToClipboard}>{isCopied ? "Copied!" : 'Copy'}</div>
        </div>
    </div>
}

const TagItem = ({el}) => {
    const {title, js, detail} = el
    // return <div className="rounded-lg border p-2 flex gap-2 w-full items-center" key={i}><a className="text-cyan-700 hover:text-cyan-500 transition-colors whitespace-nowrap" href={el.js}>{el.title}</a><div className="border-l pl-2 whitespace-pre-wrap">{el.detail}</div></div>
    return <div
        className="rounded-lg border p-2 flex gap-2 w-full items-center"
        dangerouslySetInnerHTML={{
            __html: `<a class="text-cyan-700 hover:text-cyan-500 transition-colors whitespace-nowrap" href="${js}">${title}</a><div class="border-l pl-2 whitespace-pre-wrap">${detail}</div>`
        }}>
    </div>
}

const Text = ({className, children}) => {
    return <div className={`text-cyan-700 whitespace-nowrap select-none ${className}`}>
        {children}
    </div>
}

export default App;
