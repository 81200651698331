import {proxy,subscribe} from "valtio";
import "./controller"

export const uiState = proxy({
    showFrontEnd:true, // 是否顯示所有介面；VR模式會改為不顯示介面。
    lang: 'zh', // 語言
    get isZh(){return this.lang==='zh'},
    teamEventText:'', // 接收團導對專案執行的 onEvent 事件
    shouldUpdateTeamVolume:false, // 接收團導對專案的音量控制設定
    mouse:{clientX:0, clientY:0},
    bubble:{bubbleList:[]},
    miniMap:{visible:false,fovX:128.2,fovY:814,dotWidth:7,krpano:{scene:"pano127",hLookAt:"0",fov:"90",vLookAt:"0"}},
    audioPlayer:{visible:false,updateSoundName:'learn02_1',soundName:'learn02_1',showController:true,showPlayList:false,isFirstTimeOpen:true,switchSoundCanAutoPlay:false},
    audioMessage:{visible:false,title:'',content:'',btnText:'',handler:()=>{},hasCancelBtn:false},
    intro:{visible:false,step:0},
    gitHelper:{visible:true},
})

window.frontEnd.uiState = uiState

// 還沒測試過可以改變krpano內部語言的代碼
subscribe(uiState,()=>{
    if(!window.krHelper)return
    document.getElementById("krpanoSWFObject").call(`set(tour_language, ${uiState.lang})`)
    document.getElementById("krpanoSWFObject").call("events.dispatch(changetourlanguage);")
})